import { PropsWithChildren } from 'react';
import { PublicHeader } from 'components/molecules/PublicHeader';
import { PublicFooter } from 'components/molecules/PublicFooter';
import {
  LayoutWrapper,
  LayoutWrapperInner,
} from 'components/Layout/LayoutWrapper.styled';

export const PublicLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <LayoutWrapper>
      <PublicHeader />
      <LayoutWrapperInner>{children}</LayoutWrapperInner>
      <PublicFooter />
    </LayoutWrapper>
  );
};
