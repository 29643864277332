import React from 'react';
import { Logo } from './PublicHeader.styles';
import LogoLight from 'assets/arcana_logo.png';
import {
  HeaderFooterWrapperInner,
  HeaderFooterWrapperOuter,
} from 'components/Layout/LayoutWrapper.styled';

const PublicHeader = () => {
  return (
    <HeaderFooterWrapperOuter>
      <HeaderFooterWrapperInner>
        <Logo src={LogoLight} />
      </HeaderFooterWrapperInner>
    </HeaderFooterWrapperOuter>
  );
};

export { PublicHeader };
