import React from 'react';
import {
  FlexSpaceBetween,
  HeaderFooterWrapperInner,
  HeaderFooterWrapperOuter,
  Logo,
} from 'components/Layout/LayoutWrapper.styled';
import { styled, Title } from '@resiliantinc/design-system';
import LogoDark from 'assets/arcana_logo_dark.png';

const UnstyledLink = styled('a', {
  all: 'unset',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const PublicFooter = () => {
  return (
    <HeaderFooterWrapperOuter noBorder>
      <HeaderFooterWrapperInner>
        <FlexSpaceBetween>
          <UnstyledLink href="https://arcanacapitalgroup.com/">
            <Title as="h4" css={{ fontWeight: 400 }}>
              Arcana
            </Title>
          </UnstyledLink>
          <UnstyledLink href="https://arcanacapitalgroup.com/">
            <Logo src={LogoDark} />
          </UnstyledLink>
        </FlexSpaceBetween>
      </HeaderFooterWrapperInner>
    </HeaderFooterWrapperOuter>
  );
};

export { PublicFooter };
