import React, { useCallback, useMemo, useState } from 'react';
import {
  AppLinkWrapper,
  FeedbackWrapper,
  LoginFormWrapper,
  Logo,
} from './LoginFrom.styles';
import {
  ButtonFeedback,
  Grid,
  InlineFeedback,
  Input,
  Link,
  Title,
  Text,
  Button,
} from '@resiliantinc/design-system';
import { useForm } from 'react-hook-form';
import api from 'lib/api';
import Cookies from 'js-cookie';
import LogoDark from 'assets/arcana_logo_dark.png';

const FAIL_AFTER = 60000;

function randomNumber(step = 3000) {
  return Math.floor(Math.random() * 10) * step + step;
}

const LoginFrom = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pseudoRandom] = useState(randomNumber());
  const [errorMessage, setErrorMessage] = useState('');
  const [onCancel, setOnCancel] = useState<any>();

  const { register, handleSubmit, formState, reset } = useForm<{
    email: string;
  }>({
    shouldUseNativeValidation: true,
  });

  const handleErrorRemove = useCallback(() => {
    setError(false);
    setErrorMessage('');
  }, []);

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      handleErrorRemove();
      console.log(pseudoRandom);

      const data = new FormData();
      data.append('email', email);
      data.append('clientemail', 'portaladmin@resiliant.com');
      data.append('token', '63b4be87-77ccacf8-7fa517d5-92d4bfdb');
      data.append(
        'callback',
        'https://resiliant-functions.azurewebsites.net/api/authenticateUser'
      );

      try {
        const abortController = new AbortController();
        const signal = abortController.signal;
        await new Promise(async (resolve, reject) => {
          setOnCancel(() => () => {
            abortController.abort('cancelled');
            reset();
            handleErrorRemove();
            reject('');
          });
          try {
            const res = await fetch(
              'https://idplizzapi.azurewebsites.net/API/notify',
              {
                method: 'POST',
                body: data,
                signal,
                // body: JSON.stringify({
                //   email,
                //   clientemail : 'portaladmin@resiliant.com',
                //   token : '63b4be87-77ccacf8-7fa517d5-92d4bfdb',
                //   callback:
                //     'https://arcanafunctions.azurewebsites.net/api/authenticateUser',
                // }),
              }
            );

            if (res.status === 400) {
              throw new Error('not_found');
            }
          } catch (e: any) {
            if (e.message === 'not_found') {
              setErrorMessage(
                "User not recognized. Your privacy is our priority. Please enroll with our secured identification app 'Arcana Axus':"
              );
              setError(true);
            }
            reject(e);
            return;
          }

          let currentTime = 0;
          const poll = setInterval(async () => {
            setOnCancel(() => () => {
              clearInterval(poll);
              reset();
              handleErrorRemove();
            });
            try {
              if (currentTime >= FAIL_AFTER) {
                clearInterval(poll);
                throw new Error('timeout');
              }
              // if (pseudoRandom === currentTime) {
              //   await api.getPseudoAuth(email);
              // }
              const res = await api.getTFAStatus(email);
              currentTime += 3000;
              if (res.data.isAuthenticated === true) {
                clearInterval(poll);
                Cookies.set('auth-token', 'true');
                resolve(true);
                setTimeout(() => {
                  window.open(
                    'https://arcanacapitalgroup.com/opportunities-finance-charity/',
                    '_top'
                  );
                }, 5000);
              }
            } catch (e: any) {
              console.log(e);
              clearInterval(poll);
              setError(true);
              reject(e);
            }
          }, 3000);
        });
        setSuccess(true);
      } catch (err: any) {
        if (typeof err === 'string') {
          return;
        }
        setError(true);
        setSuccess(false);
      }
    },
    //eslint-disable-next-line
    [handleErrorRemove]
  );

  const isLoading = formState.isSubmitting;

  const getFeedback = useMemo(() => {
    if (isLoading) {
      return (
        <InlineFeedback
          variant="info"
          heading="Awaiting ResiliantID Biometric Single Packet authentication to grant your access..."
        />
      );
    }
    if (error) {
      return (
        <InlineFeedback
          variant="error"
          heading={errorMessage || 'Authentication failed. Please try again.'}
        />
      );
    }
    if (success) {
      return (
        <InlineFeedback
          variant="success"
          heading="Authentication successful. Redirecting..."
        />
      );
    }
  }, [error, success, isLoading, errorMessage]);

  return (
    <LoginFormWrapper>
      <Title css={{ pb: '$4' }}>Sign In</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="Email" {...register('email')} type="email" required />
        <ButtonFeedback
          fullWidth
          isLoading={isLoading}
          isSuccess={success}
          css={{ bc: '#19537F' }}
        >
          Sign In
        </ButtonFeedback>
        {isLoading && (
          <Button
            variant="secondary"
            onClick={onCancel}
            fullWidth
            css={{ mt: '$4' }}
          >
            Cancel
          </Button>
        )}
      </form>
      <FeedbackWrapper>{getFeedback}</FeedbackWrapper>
      {error && errorMessage && (
        <Grid columns={2} gap={4} css={{ mt: '$6' }}>
          <AppLinkWrapper>
            <Logo src={LogoDark} />
            <Text>Arcana Axus for Iphone</Text>
            <Link
              href="https://apps.apple.com/us/app/arcana-axus/id1641949519?platform=iphone"
              target="_blank"
              rel="noopener, noreferrer"
            >
              Download Apple iOS Version
            </Link>
          </AppLinkWrapper>
          <AppLinkWrapper>
            <Logo src={LogoDark} />
            <Text>Arcana Axus for Android</Text>
            <Link
              href="https://play.google.com/store/apps/details?id=com.idplizz.arcana"
              target="_blank"
              rel="noopener, noreferrer"
            >
              Download Android Version
            </Link>
          </AppLinkWrapper>
        </Grid>
      )}
    </LoginFormWrapper>
  );
};

export { LoginFrom };
