import { RouteObject } from 'react-router-dom';
// import { Signup } from 'screens/Signup';
import { Login } from 'screens/Login';

// import { routePaths } from './routePaths';
// import { ProtectedRoute } from 'lib/ProtectedRoute';
// import { BaseRouterProvider } from 'providers/BaseRouterProvider';

export const appRoutes: RouteObject[] = [
  // {
  //   path: '*',
  //   element: <Navigate to={routePaths.base} replace />,
  // },
  {
    path: '*',
    element: <Login />,
  },
  // {
  //   path: routePaths.login,
  //   element: <Login />,
  // },
  // {
  //   path: routePaths.signup,
  //   element: <Signup />,
  // },
  // {
  //   path: routePaths.base,
  //   element: <ProtectedRoute component={BaseRouterProvider} />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to={routePaths.base} />,
  //     },
  //   ],
  // },
];
