import { styled } from '@resiliantinc/design-system';

export const LoginFormWrapper = styled('div', {
  maxWidth: '55rem',
  width: '100%',
  m: 'auto',
  mt: '$6',
  p: '2rem',
});

export const FeedbackWrapper = styled('div', {
  mt: '$6',
});

export const AppLinkWrapper = styled('div', {
  bc: '$blue50',
  borderRadius: '$sm',
  p: '$4',
  display: 'flex',
  jc: 'center',
  ai: 'center',
  flexDirection: 'column',
});

export const Logo = styled('img', {
  width: 100,
  mb: '$3',
});
