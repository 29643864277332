import { styled } from '@resiliantinc/design-system';

export const LayoutWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
});

export const LayoutWrapperInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  '@bp2': {
    flexDirection: 'row',
  },
});

export const HeaderFooterWrapperOuter = styled('div', {
  width: '100%',
  bc: '#19537F',
  borderBottom: '1rem solid $accent',
  color: '$white',
  variants: {
    noBorder: {
      true: {
        border: 'none',
      },
    },
  },
});

export const HeaderFooterWrapperInner = styled('div', {
  maxWidth: 1340,
  w: '100%',
  m: 'auto',
  p: '2rem',
});

export const FlexSpaceBetween = styled('div', {
  width: '100%',
  display: 'flex',
  ai: 'center',
  jc: 'space-between',
});

export const Logo = styled('img', {
  height: '3.5rem',
  display: 'block',
});
