import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { FeedbackRoot } from '@resiliantinc/design-system';

import { GlobalStyles } from 'components/atoms/GlobalStyles';
import { MultiProvider } from 'components/atoms/MultiProvider';

import api from 'lib/api';

import { IRootProvider } from './RootProvider.interface';

export const RootProvider: FC<IRootProvider> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <FeedbackRoot />
      <HelmetProvider>
        <MultiProvider
          providers={[
            <SWRConfig value={{ fetcher: api.swrFetch.bind(api) }} />,
            <BrowserRouter />,
          ]}
        >
          <>
            <Helmet defaultTitle="Sign In" titleTemplate="%s - Arcana" />
            {children}
          </>
        </MultiProvider>
      </HelmetProvider>
    </>
  );
};
