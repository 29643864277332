import React from 'react';
import { PublicLayout } from 'components/Layout';
import { LoginFrom } from 'components/organisms/LoginFrom';

export const Login = () => {
  return (
    <PublicLayout>
      <LoginFrom />
    </PublicLayout>
  );
};
